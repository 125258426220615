<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Edit Jenis TUK",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Jenis TUK",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "JenisTUK",
          href: "/master/jenistuk",
        },
        {
          text: "Edit jenis TUK",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsMenuParent: [],
      optionsMetodeSertifikasi: [],
      id: this.$route.params.id,
      // variable Page
      menu_tipe: "",

      form : {
        kode: '',
        nama: '',
        notes: '',
      },
    };
  },
  mounted() {
    this.getDataMetodeSertifikasi();
    this.getjenisByid()
  },
  methods: {
    getjenisByid(){
     let self = this
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jenis-tuk/" + self.id,
        params: {},
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };  
      axios(config)
        .then(function (response) {
          let response_data = response.data.data
          console.log(response_data)
          self.form.kode = response_data.jenis_kode
          self.form.nama = response_data.jenis_nama
          self.form.notes = response_data.notes

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jenis-tuk/" + self.id,
        data: {
          id : self.id,
          jenis_kode : self.form.kode,
          jenis_nama : self.form.nama,
          notes : self.form.notes,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman Jenis Tempat uji kom ",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-jenis-tuk" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    getDataMetodeSertifikasi() {
          let self = this;

          // load data menu parent
          var config = {
              method: "get",
              url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-metode-sertifikasi",
              headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + localStorage.access_token,
              },
          };
          axios(config)
              .then(function (response) {
                  var response_data = response.data;
                  var response_data_fix = response_data.data;
                  if (response_data.meta.code == 200) {
                      self.optionsMetodeSertifikasi = response.data.data.referensi;
                  } else {
                      Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: response_data_fix.data.message,
                      });
                  }
              })
              .catch(function (error) {
                  console.log(error);
              });
      },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <!-- <div class="form-group col-md-12">
                    <label for="tipe_menu">Kode TUK</label>
                    <input type="text" class="form-control" v-model="form.kode">
                  </div> -->
                  <!-- <div class="form-group col-md-12">
                    <label for="tipe_menu">Nama TUK</label>
                    <input type="text" class="form-control" v-model="form.kode">
                  </div> -->
                </div>
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Nama Jenis TUK</label>
                    <input type="text" class="form-control" v-model="form.nama">
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Metode Sertifikasi</label>
                      <select class="form-control" v-model="form.notes">
                          <option v-for="option in optionsMetodeSertifikasi" :key="option.id_metode_sertifikasi" :value="option.nama_metode_sertifikasi">
                              {{ option.nama_metode_sertifikasi }}
                          </option>
                      </select>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <hr>
              </div>
            
           
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"
                      ><i class="fas fa-redo-alt"></i> Reset</b-button
                    >
                    <b-button type="submit" variant="primary" class="m-1"
                      ><i class="fa fa-save"></i> Simpan TUK</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Tambah Soal Pilihan Ganda",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Soal Pilihan Ganda",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Bank Soal",
          href: "/master/bank-soal",
        },
        {
          text: "Tambah Soal Pilihan Ganda",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      meta_jawaban: [],
      optionsDimensi: [],

      // variable Page
      bank_soal_id: this.$route.params.id,
      dimensi_selected: null,
      type: "pilgan"
    };
  },
  mounted() {
    let self = this;
    // load data
    var config_dimensi = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-dimensi",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_dimensi)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsDimensi = response.data.data.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    addJawaban() {
      this.meta_jawaban.push({
        jawaban: "",
        urutan: "",
      });
    },
    removeJawaban(index) {
      this.meta_jawaban.splice(index, 1);
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/bank-soal-detail",
        data: {
          dimensi_id: self.dimensi_selected?.id_dimensi,
          bank_soal_id: self.bank_soal_id,
          soal: self.soal,
          type: self.type,
          meta_answer_multiple: JSON.stringify(self.meta_jawaban)
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master bank soal pilgan segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-bank_soal_pilgan", params:{ id:self.bank_soal_id } });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data.error;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    {{ errorArray }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Dimensi</label>
                    <v-select
                        :options="optionsDimensi"
                        label="nama_dimensi"
                        v-model="dimensi_selected"
                        placeholder="Pilih Dimensi"
                      ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Soal</label>
                    <input type="text" v-model="soal" id="" class="form-control">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Jawaban</label>
                    <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                      <thead class="bg-dark text-center text-white">
                        <tr>
                          <th>Jawaban</th>
                          <th>Urutan</th>
                          <th><button type="button" class="btn btn-sm btn-success" @click="addJawaban()"><i
                                class="fa fa-plus"></i></button></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="meta_jawaban.length == 0">
                          <td colspan="3">TIdak Ada Data</td>
                        </tr>
                        <tr v-for="(item, index) in meta_jawaban" :key="index"> 
                          <td>
                            <input type="text" class="form-control" v-model="item.jawaban">
                          </td>
                          <td>
                            <input type="text" class="form-control" v-model="item.urutan">
                          </td>
                          <td><button type="button" class="btn btn-sm btn-danger" @click="removeJawaban(index)"><i
                                class="fa fa-times"></i></button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"
                      ><i class="fas fa-redo-alt"></i> Reset</b-button
                    >
                    <b-button type="submit" variant="primary" class="m-1"
                      ><i class="fa fa-save"></i> Simpan Soal Pilihan Ganda</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

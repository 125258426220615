<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "role-menu-form",
  props: ["roles"],
  data() {
    return {
      // Catch Error Axios
      axiosCatchError: null,
      list_menu: [],
      menu_data: [],
      menu_data_selected: [],
      id_role: "",
      loading_menu: false,
    };
  },
  mounted() {
    let self = this;
    self.loading_menu = true,
    // passing id_role from roles to id_role
    self.id_role = self.roles.id_role;

    // load data menu
    var config_data_menu_ordering = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/menu/order",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_data_menu_ordering)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        console.log(response_data.meta.code);
        if (response_data.meta.code == 200) {
          self.list_menu = response_data_fix;
          self.loading_menu = false;
        } else {
          console.log(response_data_fix.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    var config_menu_role = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/menu/menu-role/"+self.id_role,
      params: {},
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_menu_role)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.menu_data = response_data_fix;
          self.menu_data_selected = response_data_fix;
          self.loading_menu = false;
        } else {
          console.log(response_data_fix.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    checkCheckbox() {
      let self = this;
      console.log(self.menu_data);
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/menu/menu-role/"+self.id_role,
        data: {
          id_role: self.id_role,
          menu_data: JSON.stringify(self.menu_data_selected),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Konfigurasi Menu Berhasil Disimpan.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              // location.reload
              self.$router.push({ name: "all-menu" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="col-lg-12">
    <div class="card">
      <b-form class="p-2" @submit.prevent="StoreData">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="card-title">Silahkan Konfigurasi Menu Role</div>
            </div>
            <div class="col-lg-12">
              <div class="table-responsive">
                <table class="table table-nowrap mb-0 mt-2">
                  <tbody>
                    <tr>
                      <th scope="row" colspan="3">Informasi Detail Role</th>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 5%">Nama Role</th>
                      <th style="width: 1%">:</th>
                      <td>{{ roles.role_name }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Deskripsi Role</th>
                      <th>:</th>
                      <td>{{ roles.role_description }}</td>
                    </tr>
                    <tr>
                      <th scope="row" colspan="3">Akses Menu</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-12 mt-3">
              <div v-if="loading_menu">
                <div class="p-2">
                  <b
                    ><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading List
                    Menu</b
                  >
                </div>
              </div>
              <div v-else>
                <ul v-for="menu_parent in list_menu" :key="menu_parent.id">
                  <li class="pt-0">
                    <b-form-checkbox
                      :value="menu_parent.id"
                      v-model="menu_data_selected"
                      @change="checkCheckbox($event)"
                    >
                      <i :class="menu_parent.icon ? menu_parent.icon : ''"></i>
                      {{ menu_parent.name }}
                    </b-form-checkbox>

                    <ul v-for="menu_sub in menu_parent.sub" :key="menu_sub.id">
                      <li class="pt-2">
                        <b-form-checkbox
                          :value="menu_sub.id"
                          v-model="menu_data_selected"
                          @change="checkCheckbox($event)"
                        >
                          {{ menu_sub.name }}
                        </b-form-checkbox>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-6">
              <div class="text-start">
                <router-link :to="{ name: 'all-menu' }" class="btn btn-warning"
                  ><i class="fa fa-arrow-left"></i> Kembali</router-link
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="text-end">
                <b-button type="reset" variant="danger"
                  ><i class="fa fa-redo-alt"></i> Reset</b-button
                >
                &nbsp;
                <b-button type="submit" variant="primary"
                  ><i class="fa fa-save"></i> Simpan</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  page: {
    title: "Tambah Skema Sertifikasi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Skema Sertifikasi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Skema Sertifikasi",
          href: "/master/skema-sertifikasi",
        },
        {
          text: "Tambah Skema Sertifikasi",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsKategori: [],
      optionsSubKategori: [],

      // variable Page
      skema_sertifikasi_kategori: "",
      skema_sertifikasi_kategori_sub: "",
      logo: null,
      status_skkk: false,
      skema_sertifikasi_dokumen: null,
    };
  },
  mounted() {
    // GET SKEMA SERTIFIKASI KATEGORI
    let self = this;

    // load data menu parent
    var config_data_menu_parent = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/load-skema-sertifikasi-kategori",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_data_menu_parent)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsKategori = response.data.data.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/skema-sertifikasi",
        data: {
          kode_skema_sertifikasi: self.kode_skema_sertifikasi,
          nama_skema_sertifikasi: self.nama_skema_sertifikasi,
          skema_kategori_sub_id: self.skema_sertifikasi_kategori_sub?.id_skema_sertifikasi_kategori_sub,
          skema_sertifikasi_dokumen: self.skema_sertifikasi_dokumen,
          status_skkk: self.status_skkk,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master skema sertifikasi segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-skema_sertifikasi" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data.error;
          Swal.close();
        });
    },
    getSkemaSertifikasiKategoriSub() {
      let self = this;
      var kategori_id = 1;
      if(self.skema_sertifikasi_kategori?.id_skema_sertifikasi_kategori){
        kategori_id = self.skema_sertifikasi_kategori?.id_skema_sertifikasi_kategori;
      }
      var config_load_skema_sertifikasi_kategori_sub= {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/load-skema-sertifikasi-kategori-sub/" + kategori_id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_load_skema_sertifikasi_kategori_sub)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsSubKategori = response.data.data.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    inputFileLogo() {
      let self = this;
      if ($("#formLogo")[0].files[0]) {
        if ($("#formLogo")[0].files[0].size < 2242880) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formLogo")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.logo = path_file;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    inputFileSkemaSertifikasi() {
      let self = this;
      if ($("#formFileSkemaSertifikasi")[0].files[0]) {
        if($("#formFileSkemaSertifikasi")[0].files[0].type == 'application/pdf'){
          $("#uploadFileSkemaLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formFileSkemaSertifikasi")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadFileSkemaLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.skema_sertifikasi_dokumen = path_file;
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Gagal...",
            text: "Format File Tidak Sesuai!",
          });
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    {{ errorArray }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="">Kategori</label>
                    <v-select
                        :options="optionsKategori"
                        label="nama_skema_sertifikasi_kategori"
                        v-model="skema_sertifikasi_kategori"
                        @update:modelValue="getSkemaSertifikasiKategoriSub"
                        placeholder="Pilih Kategori"
                      ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="">Sub Kategori</label>
                    <v-select
                        :options="optionsSubKategori"
                        label="nama_skema_sertifikasi_kategori_sub"
                        v-model="skema_sertifikasi_kategori_sub"
                        placeholder="Pilih Kategori"
                      ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kode Skema Sertifikasi</label>
                    <input type="text" class="form-control" v-model="kode_skema_sertifikasi">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Nama Skema Sertifikasi</label>
                    <input type="text" class="form-control" v-model="nama_skema_sertifikasi">
                  </div>
                  <!-- <div class="form-group col-md-12">
                    <label for="tipe_menu">Logo Skema</label>
                    <input
                      class="form-control"
                      type="file"
                      id="formLogo"
                      v-on:change="inputFileLogo()"
                    />
                    <input type="hidden" v-model="logo">
                    <div
                      class="respond-input-file float-left"
                      id="uploadLoading"
                    ></div>
                    <small class="float-right" style="color: red"
                      >*) Max File 2 MB</small
                    >
                  </div> -->
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Dokumen Skema Sertifikasi</label>
                    <input class="form-control" type="file" id="formFileSkemaSertifikasi" accept="application/pdf" v-on:change="inputFileSkemaSertifikasi()" />
                    <input type="hidden" v-model="skema_sertifikasi_dokumen">
                    <div class="respond-input-file float-left" id="uploadFileSkemaLoading"></div>
                    <small class="float-right" style="color: red">*) Max File 2 MB</small>
                  </div>
                  <div class="form-group col-md-12">
                    <div class="mb-3 mt-3 form-check">
                      <input type="checkbox" class="form-check-input" v-model="status_skkk">
                      <label class="form-check-label"><strong>Apakah SKKK baru?</strong></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"
                      ><i class="fas fa-redo-alt"></i> Reset</b-button
                    >
                    <b-button type="submit" variant="primary" class="m-1"
                      ><i class="fa fa-save"></i> Simpan Skema Sertifikasi</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

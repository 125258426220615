<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { debounce } from "@/helpers/debounce";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';


/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Pegawai",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
    VueJsonPretty,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id" },
      { width: "auto", label: "NPK", name: "npk" },
      // { width: "auto", label: "New NIK", name: "new_nik" },
      { width: "auto", label: "Nama", name: "nama" },
      { width: "auto", label: "Jabatan", name: "jabatan" },
      // { width: "auto", label: "Nomor Telp", name: "nomor_telp"},
      { width: "auto", label: "Email", name: "email" },
      { width: "auto", label: "Created At", name: "created_at" },
      { width: "auto", label: "Updated At", name: "updated_at" },
      { width: "150px", label: "Data", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      // { width: "175px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];
    let columns_log = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "sync nama",name:"-"},
      { width: "auto", label: "nama",name:"-"},
      { width: "auto", label: "created at", name: "created_at" },
      { width: "auto", label: "updated at", name: "updated_at" },

    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    columns_log.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Pegawai",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Pegawai",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      table_data_log: [],
      columns: columns,
      columns_log: columns_log,
      sortKey: "id", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      currentTablePageLog: "",
      showModalDetail: false,
      dataDetail: null,

      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: null,
        sort_order: "desc",
        filter_status: true,
        filter_tanggal_mulai: null,
        filter_tanggal_selesai: null,
      },
      tableDataLog: {
        per_page: 10,
        search: "",
        sort_field: null,
        sort_order: "desc",
        filter_status: true,
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      pagination_log: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      showModalLog: false,
      loadingTableLog: false,
      lastSyncDate: null,
    };
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  mounted() {
    let self = this;
    // self.cekAksesMenu();
    self.getLastSinkron();
    self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    
    // cekAksesMenu() {
    //   let self = this;
    //   var config_axios = {
    //     method: "get",
    //     url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
    //     params: {
    //       role_id: self.sessionRoleId,
    //       link_name: self.namePathUrl,
    //     },
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: "Bearer " + localStorage.access_token,
    //     },
    //   };
    //   axios(config_axios)
    //     .then((response) => {
    //       let response_data_fix = response.data.data.status_access;
    //       if (response.data.meta.code == 200) {
    //         if (response_data_fix == false) {
    //           Swal.fire({
    //             icon: "warning",
    //             title: "Oppss",
    //             text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
    //             timer: 2000,
    //             timerProgressBar: true,
    //             showConfirmButton: false,
    //           }).then((result) => {
    //             /* Read more about handling dismissals below */
    //             if (result.dismiss === Swal.DismissReason.timer) {
    //               self.$router.push({ name: "dashboard" });
    //             }
    //           });
    //         }
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Oops...",
    //           text: response_data_fix.data.message,
    //         });
    //       }
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/pegawai"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-user",
          role_id: self.sessionRoleId
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            // if (self.tableData.draw == response_data_fix.draw) {
            self.table_data = response_data_fix.list;
            self.configPagination(response_data_fix.pagination);
            self.currentTablePage = response_data_fix.pagination.current_page;
            // }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    changeStatusData(status, id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah anda yakin ingin mengaktifkan data tersebut?";
      } else {
        text_alert = "Apakah anda yakin ingin menonaktifkan data tersebut?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users/"+id+'/status',
            data: {
              id: id,
              status: status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users/"+id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },

    toPage: function (url) {
      let self = this;
      self.getDataTable(url);
    },
    syncDataPegawai(){
      let self = this;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Mohon tunggu sebentar...",
        showConfirmButton: false,
      });
      var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/pegawai/sinkron",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
      axios(config).then((response) => {
        var response_data = response.data;
        if (response_data.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.data.message,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data berhasil disinkronisasi.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              self.getDataTable();
            }
          });
        }
      });
    },
    ModalLog(){
      let self = this;
      self.showModalLog = true;
      self.getDataLogTable();
    },
    ModalDetail(data){
      let self = this;
      self.showModalDetail = true;
      self.dataDetail = data;
    },
    getLastSinkron(){
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/pegawai/get-last-sync-date",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
            self.lastSyncDate = response.data;
            console.log(self.lastSyncDate);
         
        }).catch((errors) => {
          console.log(errors);
        });
    },
    getDataLogTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/pegawai/log-sync" ) {
      let self = this;
    
      self.loadingTableLog = true;
      self.tableDataLog.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableDataLog,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-user",
          role_id: self.sessionRoleId
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            // if (self.tableData.draw == response_data_fix.draw) {
            self.table_data_log = response_data_fix.list_data.data;
            console.log(self.table_data_log);
            // self.configPagination(response_data_fix.pagination);
            // self.currentTablePageLog = response_data_fix.pagination.current_page;
            // }
            self.loadingTableLog = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Semua Pegawai</div>
              </div>
              
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-2">
                    <label for="statusTable">Status</label>
                    <select class="form-select" id="statusTable" v-model="tableData.filter_status"
                      @change="getDataTable()">
                      <option value=true>ENABLE</option>
                      <option value=false>DISABLE</option>
                    </select>
                  </div>
                  <div class="form-group col-2">
                    <label for="statusTable">Tanggal Mulai</label>
                    <input type="date" class="form-control" v-model="tableData.filter_tanggal_mulai" />
                  </div>
                  <div class="form-group col-2">
                    <label for="statusTable">Tanggal Selesai</label>
                    <input type="date" class="form-control" v-model="tableData.filter_tanggal_selesai" />
                  </div>
                  <!-- button fiter -->
                  <div class="form-group col-2 mt-4">
                    <button class="btn btn-sm btn-primary mx-2" @click="getDataTable()"><i class="fa fa-filter"></i>
                      Filter
                    </button>
                  </div>
                  

                  <div class="form-group col-md-4 mt-4 text-end" v-if="sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070'">
                    <label for="statusTable"><b-badge variant="success">Last sinkron: {{ lastSyncDate }}  </b-badge></label>
                    <button class="btn btn-sm btn-success mx-2" @click="ModalLog"><i class="bx bx-list-ol mr-2"></i>Log</button>
                    <button class="btn btn-sm btn-success" @click="syncDataPegawai"><i class="fa fa-sync"></i> Sinkron</button>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%"
                            v-model="tableData.per_page" @change="getDataTable()">
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..."
                      v-model="tableData.search" @input="searchDatatable()" />
                  </div>
                </div>
              </div>
              <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="9">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td class="text-center" colspan="9">Data Tidak Tersedia</td>
                  </tr>
                  <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                    <td class="text-center">
                      {{ ((parseInt(tableData.per_page) * parseInt(currentTablePage)) - parseInt(tableData.per_page)) + key_data + 1}}.
                    </td>
                    <td class="text-center">
                      {{ row_data.npk ?? '-' }}
                    </td>
                    <!-- <td class="text-center">
                      {{ row_data.new_nik ?? '-' }}
                    </td> -->
                    <td class="text-center">
                      {{ row_data.nama }}
                    </td>
                    <td class="text-center">
                      {{ row_data.jabatan ?? '-' }}
                    </td>
                    <!-- <td class="text-center">
                      {{ row_data.nomor_telp ?? '-' }}
                    </td> -->
                    <td class="text-center">
                      {{ row_data.email ?? '-' }}
                    </td>
                    <td class="text-center">
                      {{ row_data.created_at ?? '-' }}
                    </td>
                    <td class="text-center">
                      {{ row_data.updated_at ?? '-' }}
                    </td>
                    <td class="text-center">
                    
                      <b-button type="submit" variant="primary" class="m-1" @click="ModalDetail(row_data)">
                        <i class="fa fa-eye"></i> Lihat Data 
                      </b-button>
                    </td>
                    <!-- <td class="text-center">
                      <div v-if="row_data.status == true">
                        <div class="badge badge-pill badge-soft-success font-size-12">
                          ENABLE
                        </div>
                      </div>
                      <div v-else>
                        <div class="badge badge-pill badge-soft-danger font-size-12">
                          DISABLE
                        </div>
                      </div>
                    </td> -->
                    <!-- <td class="text-center">
                      <div v-if="row_data.status == 'ENABLE'">
                        <div class="btn-group">
                          <button type="button" class="btn btn-danger btn-sm" v-on:click="
                            changeStatusData('DISABLE', row_data.user_id)
                            ">
                            <i class="fa fa-times"></i> Disable
                          </button>
                          <router-link :to="{
                            name: 'edit-user',
                            params: { id: row_data.user_id },
                          }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                        </div>
                      </div>
                      <div v-else>
                        <div class="btn-group">
                          <button type="button" class="btn btn-warning btn-sm" v-on:click="
                            changeStatusData('ENABLE', row_data.user_id)
                            ">
                            <i class="fas fa-undo"></i> Restore
                          </button>
                          <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteData(row_data.user_id)">
                            <i class="fa fa-trash"></i> Hapus
                          </button>
                        </div>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </datatable>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="showModalLog" title="log sinkron" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="row">
                <div class="col-md-12">
                      <datatable :columns="columns_log" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                        <tbody>
                          <tr v-if="loadingTableLog">
                            <td colspan="9" class="text-center">
                              <i class="fas fa-spinner fa-spin"></i> Loading...
                            </td>
                          </tr>
                          <tr v-else-if="table_data_log == ''">
                            <td class="text-center" colspan="9">Data Tidak Tersedia</td>
                          </tr>
                          <tr v-else v-for="(row_data, key_data) in table_data_log" :key="key_data">
                            <td class="text-center">
                              <div v-if="key_data + 1 == 10">
                                {{ currentTablePage }}0.
                              </div>
                              <div v-else>
                                {{
                                  currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                                }}{{ key_data + 1 }}.
                              </div>
                            </td>
                            <td class="text-center">
                              {{ row_data.sync_name ?? '-' }}
                            </td>
                            <td class="text-center">
                              {{ row_data.created_name ?? '-' }}
                            </td>
                            <td class="text-center">
                              {{ new Date(row_data.created_at).toLocaleString() ?? '-' }}
                            </td>
                            <td class="text-center">
                              {{ new Date(row_data.updated_at).toLocaleString() ?? '-' }}
                            </td>
                          </tr>
                          </tbody>  
                      </datatable>
                  </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalDetail" title="log" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="row">
                <div class="col-md-12" v-if="dataDetail != null">
                    <vue-json-pretty :data="dataDetail?.json_data" :view="1"></vue-json-pretty>
                  </div>
            </div>
        </div>
    </b-modal>
</template>

import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: "hash",
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    const dateLogin = localStorage.dateLogin;
    var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
    var date_now = [year, month, day].join("-");
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
    if (authRequired) {
        const aksesToken = localStorage.access_token;
        if (aksesToken == null || dateLogin != date_now) {
            return next("/login");
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
// import axios from "axios";
import Swal from "sweetalert2";
// import moment from "moment";

export default {
  page: {
    title: "Log Aktivitas",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_menu_master" },
      { width: "auto", label: "Nama Menu", name: "menu_master_nama" },
      { width: "100px", label: "Type", name: "menu_master_type" },
      { width: "125px", label: "Icon Menu", name: "menu_master_icon" },
      { width: "auto", label: "Link Path", name: "menu_master_link_path" },
      { width: "auto", label: "Link Name", name: "menu_master_link_name" },
      { width: "80px", label: "Urutan", name: "menu_master_urutan" },
      { width: "75px", label: "Parent", name: "menu_master_parent" },
      { width: "150px", label: "Created At", name: "created_at" },
      { width: "150px", label: "Updated At", name: "updated_at" },
      { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Log Aktivitas",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Log Aktivitas",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      // variable Page Table
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_menu_master", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_status: "ENABLE", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
  },
  methods: {
    ubahStatus(id) {
      console.log(id);
      var alert_text = "Apakah Anda yakin untuk mengubah status?";
      Swal.fire({
        title: "Peringatan !",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close();
        } else {
          Swal.close();
        }
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Log Aktivitas</div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  &nbsp;
                  <router-link
                    :to="{ name: 'add-halaman' }"
                    class="btn btn-sm btn-success"
                    ><i class="fa fa-plus"></i> Tambah Data</router-link
                  >
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2">
                    <label for="statusTable">Tanggal Awal</label>
                    <input type="date" class="form-control">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="statusTable">Tanggal Akhir</label>
                    <input type="date" class="form-control">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="statusTable">Pengguna</label>
                    <input type="date" class="form-control">
                  </div>
                  <div class="form-group col-md-4 mt-4">
                    <button class="btn btn-primary"><i class="fa fa-filter"></i> Filter</button>
                    <button class="btn btn-success" style="margin-left: 1px;"><i class="fas fa-file-excel-o"></i>Export Data</button>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.length"
                            @change="getDataTable()"
                          >
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="getDataTable()"
                    />
                  </div>
                </div>
                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                  <thead class="bg-dark text-center text-white">
                    <tr>
                      <th>No</th>
                      <th>Pengguna</th>
                      <th>Nama Aktivitas</th>
                      <th>Tanggal Aktivitas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>User</td>
                      <td>Login</td>
                      <td>31 Juli 2023</td>
                    </tr>
                  </tbody>
              </table>
              </div>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

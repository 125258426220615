// import store from "@/state/store";

export default [
    {
        path: "/coming-soon",
        name: "coming-soon",
        component: () => import("./views/utility/coming-soon"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./views/account/login"),
    },

    {
        path: "/",
        name: "dashboard",
        meta: { authRequired: true },
        component: () => import("./views/dashboards/default"),
    },
    {
        path: "/profile",
        name: "profile",
        meta: { authRequired: true },
        component: () => import("./views/account/profile"),
    },
    {
        path: "/logout",
        name: "logout",
        meta: { authRequired: true },
        component: () => import("./views/account/logout"),
    },

    // routes Master

    // routes master role
    {
        path: "/master/role",
        name: "all-role",
        meta: { authRequired: true },
        component: () => import("./views/master/role/all-role"),
    },
    {
        path: "/master/role/tambah",
        name: "add-role",
        meta: { authRequired: true },
        component: () => import("./views/master/role/add-role"),
    },
    {
        path: "/master/role/edit/:id",
        name: "edit-role",
        meta: { authRequired: true },
        component: () => import("./views/master/role/edit-role"),
    },

    // routes master menu
    {
        path: "/master/menu",
        name: "all-menu",
        meta: { authRequired: true },
        component: () => import("./views/master/menu/all-menu"),
    },
    {
        path: "/master/menu/tambah",
        name: "add-menu",
        meta: { authRequired: true },
        component: () => import("./views/master/menu/add-menu"),
    },
    {
        path: "/master/menu/edit/:id",
        name: "edit-menu",
        meta: { authRequired: true },
        component: () => import("./views/master/menu/edit-menu"),
    },
    {
        path: "/master/menu/ordering",
        name: "ordering-menu",
        meta: { authRequired: true },
        component: () => import("./views/master/menu/ordering-menu"),
    },
    {
        path: "/master/menu/role-menu",
        name: "role-menu",
        meta: { authRequired: true },
        component: () => import("./views/master/menu/role-menu"),
    },

    // routes master user
    {
        path: "/master/user",
        name: "all-user",
        meta: { authRequired: true },
        component: () => import("./views/master/user/all-user"),
    },
    {
        path: "/master/user/tambah",
        name: "add-user",
        meta: { authRequired: true },
        component: () => import("./views/master/user/add-user"),
    },
    {
        path: "/master/user/edit/:id",
        name: "edit-user",
        meta: { authRequired: true },
        component: () => import("./views/master/user/edit-user"),
    },

    // routes master konfigurasi
    {
        path: "/master/konfigurasi",
        name: "all-konfigurasi",
        meta: { authRequired: true },
        component: () => import("./views/master/konfigurasi/all-konfigurasi"),
    },
    // routes master pegawai
    {
        path: "/master/pegawai",
        name: "all-pegawai",
        meta: { authRequired: true },
        component: () => import("./views/master/pegawai/all-pegawai"),
    },
    // routes master metode sertifikasi
    {
        path: "/master/metode-sertifikasi",
        name: "all-metode_sertifikasi",
        meta: { authRequired: true },
        component: () => import("./views/master/metode-sertifikasi/all-metode_sertifikasi"),
    },
    {
        path: "/master/metode-sertifikasi/add",
        name: "add-metode_sertifikasi",
        meta: { authRequired: true },
        component: () => import("./views/master/metode-sertifikasi/add-metode_sertifikasi"),
    },
    {
        path: "/master/metode-sertifikasi/edit/:id",
        name: "edit-metode_sertifikasi",
        meta: { authRequired: true },
        component: () => import("./views/master/metode-sertifikasi/edit-metode_sertifikasi"),
    },

    // routes master provinsi
    {
        path: "/master/provinsi",
        name: "all-provinsi",
        meta: { authRequired: true },
        component: () => import("./views/master/provinsi/all-provinsi"),
    },
    {
        path: "/master/provinsi/add",
        name: "add-provinsi",
        meta: { authRequired: true },
        component: () => import("./views/master/provinsi/add-provinsi"),
    },
    {
        path: "/master/provinsi/edit/:id",
        name: "edit-provinsi",
        meta: { authRequired: true },
        component: () => import("./views/master/provinsi/edit-provinsi"),
    },

    // routes master provinsi
    {
        path: "/master/kab-kota",
        name: "all-kab_kota",
        meta: { authRequired: true },
        component: () => import("./views/master/kab-kota/all-kab_kota"),
    },
    {
        path: "/master/kab-kota/add",
        name: "add-kab_kota",
        meta: { authRequired: true },
        component: () => import("./views/master/kab-kota/add-kab_kota"),
    },
    {
        path: "/master/kab-kota/edit/:id",
        name: "edit-kab_kota",
        meta: { authRequired: true },
        component: () => import("./views/master/kab-kota/edit-kab_kota"),
    },

    // routes master kecamatan
    {
        path: "/master/kecamatan",
        name: "all-kecamatan",
        meta: { authRequired: true },
        component: () => import("./views/master/kecamatan/all-kecamatan"),
    },
    {
        path: "/master/kecamatan/add",
        name: "add-kecamatan",
        meta: { authRequired: true },
        component: () => import("./views/master/kecamatan/add-kecamatan"),
    },
    {
        path: "/master/kecamatan/edit/:id",
        name: "edit-kecamatan",
        meta: { authRequired: true },
        component: () => import("./views/master/kecamatan/edit-kecamatan"),
    },

    // routes master satuan
    {
        path: "/master/satuan",
        name: "all-satuan",
        meta: { authRequired: true },
        component: () => import("./views/master/satuan/all-satuan"),
    },
    {
        path: "/master/satuan/add",
        name: "add-satuan",
        meta: { authRequired: true },
        component: () => import("./views/master/satuan/add-satuan"),
    },
    {
        path: "/master/satuan/edit/:id",
        name: "edit-satuan",
        meta: { authRequired: true },
        component: () => import("./views/master/satuan/edit-satuan"),
    },

    // routes master peralatan_tuk
    {
        path: "/master/peralatan-tuk",
        name: "all-peralatan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/peralatan-tuk/all-peralatan_tuk"),
    },
    {
        path: "/master/peralatan-tuk/add",
        name: "add-peralatan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/peralatan-tuk/add-peralatan_tuk"),
    },
    {
        path: "/master/peralatan-tuk/edit/:id",
        name: "edit-peralatan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/peralatan-tuk/edit-peralatan_tuk"),
    },

    // routes master elemen
    {
        path: "/master/elemen",
        name: "all-elemen",
        meta: { authRequired: true },
        component: () => import("./views/master/elemen/all-elemen"),
    },
    {
        path: "/master/elemen/add",
        name: "add-elemen",
        meta: { authRequired: true },
        component: () => import("./views/master/elemen/add-elemen"),
    },
    {
        path: "/master/elemen/edit/:id",
        name: "edit-elemen",
        meta: { authRequired: true },
        component: () => import("./views/master/elemen/edit-elemen"),
    },

    // routes master elemen
    {
        path: "/master/kriteria-kerja",
        name: "all-kriteria_kerja",
        meta: { authRequired: true },
        component: () => import("./views/master/kriteria-kerja/all-kriteria_kerja"),
    },
    {
        path: "/master/kriteria-kerja/add",
        name: "add-kriteria_kerja",
        meta: { authRequired: true },
        component: () => import("./views/master/kriteria-kerja/add-kriteria_kerja"),
    },
    {
        path: "/master/kriteria-kerja/edit/:id",
        name: "edit-kriteria_kerja",
        meta: { authRequired: true },
        component: () => import("./views/master/kriteria-kerja/edit-kriteria_kerja"),
    },

    // routes master unit kompetensi
    {
        path: "/master/unit-kompetensi",
        name: "all-unit_kompetensi",
        meta: { authRequired: true },
        component: () => import("./views/master/unit-kompetensi/all-unit_kompetensi"),
    },
    {
        path: "/master/unit-kompetensi/add",
        name: "add-unit_kompetensi",
        meta: { authRequired: true },
        component: () => import("./views/master/unit-kompetensi/add-unit_kompetensi"),
    },
    {
        path: "/master/unit-kompetensi/edit/:id",
        name: "edit-unit_kompetensi",
        meta: { authRequired: true },
        component: () => import("./views/master/unit-kompetensi/edit-unit_kompetensi"),
    },

    // routes master skema sertifikasi
    {
        path: "/master/skema-sertifikasi",
        name: "all-skema_sertifikasi",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi/all-skema_sertifikasi"),
    },
    {
        path: "/master/skema-sertifikasi/add",
        name: "add-skema_sertifikasi",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi/add-skema_sertifikasi"),
    },
    {
        path: "/master/skema-sertifikasi/edit/:id",
        name: "edit-skema_sertifikasi",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi/edit-skema_sertifikasi"),
    },

    // routes master skema sertifikasi kategori
    {
        path: "/master/skema-sertifikasi-kategori",
        name: "all-skema_sertifikasi_kategori",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi-kategori/all-skema_sertifikasi_kategori"),
    },
    {
        path: "/master/skema-sertifikasi-kategori/add",
        name: "add-skema_sertifikasi_kategori",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi-kategori/add-skema_sertifikasi_kategori"),
    },
    {
        path: "/master/skema-sertifikasi-kategori/edit/:id",
        name: "edit-skema_sertifikasi_kategori",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi-kategori/edit-skema_sertifikasi_kategori"),
    },

    // routes master skema sertifikasi kategori sub
    {
        path: "/master/skema-sertifikasi-kategori-sub",
        name: "all-skema_sertifikasi_kategori_sub",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi-kategori-sub/all-skema_sertifikasi_kategori_sub"),
    },
    {
        path: "/master/skema-sertifikasi-kategori-sub/add",
        name: "add-skema_sertifikasi_kategori_sub",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi-kategori-sub/add-skema_sertifikasi_kategori_sub"),
    },
    {
        path: "/master/skema-sertifikasi-kategori-sub/edit/:id",
        name: "edit-skema_sertifikasi_kategori_sub",
        meta: { authRequired: true },
        component: () => import("./views/master/skema-sertifikasi-kategori-sub/edit-skema_sertifikasi_kategori_sub"),
    },

    // routes master bukti dokumen
    {
        path: "/master/bukti-dokumen",
        name: "all-bukti_dokumen",
        meta: { authRequired: true },
        component: () => import("./views/master/bukti-dokumen/all-bukti_dokumen"),
    },
    {
        path: "/master/bukti-dokumen/add",
        name: "add-bukti_dokumen",
        meta: { authRequired: true },
        component: () => import("./views/master/bukti-dokumen/add-bukti_dokumen"),
    },
    {
        path: "/master/bukti-dokumen/edit/:id",
        name: "edit-bukti_dokumen",
        meta: { authRequired: true },
        component: () => import("./views/master/bukti-dokumen/edit-bukti_dokumen"),
    },

    // routes master tuk
    {
        path: "/master/tuk",
        name: "all-tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/tuk/all-tuk"),
    },
    {
        path: "/master/tuk/add",
        name: "add-tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/tuk/add-tuk"),
    },
    {
        path: "/master/tuk/edit/:id",
        name: "edit-tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/tuk/edit-tuk"),
    },

    // routes master jenis tuk
    {
        path: "/master/jenistuk",
        name: "all-jenis-tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/tuk-jenis/all-jenis-tuk"),
    },
    {
        path: "/master/jenistuk/add",
        name: "add-jenis-tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/tuk-jenis/add-jenis-tuk"),
    },
    {
        path: "/master/jenistuk/edit/:id",
        name: "edit-jenis-tuk",
        meta: { authRequired: true },
        component: () => import("./views/master/tuk-jenis/edit-jenis-tuk"),
    },

    // routes master ruangan
    {
        path: "/master/ruangan",
        name: "all-ruangan",
        meta: { authRequired: true },
        component: () => import("./views/master/ruangan/all-ruangan"),
    },
    {
        path: "/master/ruangan/add",
        name: "add-ruangan",
        meta: { authRequired: true },
        component: () => import("./views/master/ruangan/add-ruangan"),
    },
    {
        path: "/master/ruangan/edit/:id",
        name: "edit-ruangan",
        meta: { authRequired: true },
        component: () => import("./views/master/ruangan/edit-ruangan"),
    },

    // routes master bank soal
    {
        path: "/master/bank-soal",
        name: "all-bank_soal",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/all-bank_soal"),
    },
    {
        path: "/master/bank-soal/add",
        name: "add-bank_soal",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/add-bank_soal"),
    },
    {
        path: "/master/bank-soal/edit/:id",
        name: "edit-bank_soal",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/edit-bank_soal"),
    },
    {
        path: "/master/bank-soal/lisan/:id",
        name: "all-bank_soal_lisan",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/lisan/all-bank_soal_lisan"),
    },
    {
        path: "/master/bank-soal/esai/:id",
        name: "all-bank_soal_esai",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/esai/all-bank_soal_esai"),
    },
    {
        path: "/master/bank-soal/pilgan/:id",
        name: "all-bank_soal_pilgan",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/pilgan/all-bank_soal_pilgan"),
    },
    {
        path: "/master/bank-soal/skenario/:id",
        name: "all-bank_soal_skenario",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/skenario/all-bank_soal_skenario"),
    },
    {
        path: "/master/bank-soal/add/lisan/:id",
        name: "add-bank_soal_lisan",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/lisan/add-bank_soal_lisan"),
    },
    {
        path: "/master/bank-soal/add/esai/:id",
        name: "add-bank_soal_esai",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/esai/add-bank_soal_esai"),
    },
    {
        path: "/master/bank-soal/add/pilgan/:id",
        name: "add-bank_soal_pilgan",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/pilgan/add-bank_soal_pilgan"),
    },
    {
        path: "/master/bank-soal/add/skenario/:id",
        name: "add-bank_soal_skenario",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/skenario/add-bank_soal_skenario"),
    },
    {
        path: "/master/bank-soal/edit/lisan/:id",
        name: "edit-bank_soal_lisan",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/lisan/edit-bank_soal_lisan"),
    },
    {
        path: "/master/bank-soal/edit/esai/:id",
        name: "edit-bank_soal_esai",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/esai/edit-bank_soal_esai"),
    },
    {
        path: "/master/bank-soal/edit/pilgan/:id",
        name: "edit-bank_soal_pilgan",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/pilgan/edit-bank_soal_pilgan"),
    },
    {
        path: "/master/bank-soal/edit/skenario/:id",
        name: "edit-bank_soal_skenario",
        meta: { authRequired: true },
        component: () => import("./views/master/bank-soal/skenario/edit-bank_soal_skenario"),
    },

    // Penusunan MUK

    {
        path: "/penyusunan-muk",
        name: "all-daftar_muk",
        meta: { authRequired: true },
        component: () => import("./views/penyusunan-muk/all-surat_tugas"),
    },
    {
        path: "/penyusunan-muk/konfigurasi-dokumen-muk/edit/:id/:id_penyusunan",
        name: "edit-konfigurasi-penyusunan-muk",
        meta: { authRequired: true },
        component: () => import("./views/penyusunan-muk/edit-konfigurasi"),
    },
    {
        path: "/penyusunan-muk/add-surat_tugas_penyusunan",
        name: "add-surat_tugas_penyusunan",
        meta: { authRequired: true },
        component: () => import("./views/penyusunan-muk/add-surat_tugas_penyusunan"),
    },
    {
        path: "/penyusunan-muk/edit-surat_tugas_penyusunan/:id",
        name: "edit_surat_tugas_penyusunan",
        meta: { authRequired: true },
        component: () => import("./views/penyusunan-muk/edit-surat_tugas_penyusunan"),
    },
    {
        path: "/penyusunan-muk/detail-penyusunan/:id",
        name: "detail-penyusunan",
        meta: { authRequired: true },
        component: () => import("./views/penyusunan-muk/detail-penyusunan"),
    },
    // Validasi MUK
    {
        path: "/validasi-muk",
        name: "all-daftar_muk-validasi",
        meta: { authRequired: true },
        component: () => import("./views/validasi-muk/all-surat_tugas"),
    },
    {
        path: "/validasi-muk/add-surat_tugas_validasi/:id_penyusunan",
        name: "add-surat_tugas_validasi",
        meta: { authRequired: true },
        component: () => import("./views/validasi-muk/add-surat_tugas_validasi"),
    },
    // MUK Terbit
    {
        path: "/muk-terbit",
        name: "all-muk_terbit",
        meta: { authRequired: true },
        component: () => import("./views/validasi-muk/all-muk_terbit"),
    },
    // pengunaan-tuk
    {
        path: "/penggunaan-tuk",
        name: "all-penggunaan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/penggunaan-tuk/all-penggunaan_tuk"),
    },
    {
        path: "/penggunaan-tuk/add-penggunaan_tuk",
        name: "add-penggunaan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/penggunaan-tuk/add-penggunaan_tuk"),
    },
    {
        path: "/penggunaan-tuk/detail-penggunaan_tuk/:id",
        name: "detail-penggunaan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/penggunaan-tuk/detail-penggunaan_tuk"),
    },

    //VERIFIKASI TUK
    {
        path: "/verifikasi-tuk",
        name: "all-verifikasi_tuk",
        meta: { authRequired: true },
        component: () => import("./views/verifikasi-tuk/all-verifikasi_tuk"),
    },
    {
        path: "/verifikasi-tuk/add-verifikasi_tuk/",
        name: "add-verifikasi_tuk",
        meta: { authRequired: true },
        component: () => import("./views/verifikasi-tuk/add-verifikasi_tuk"),
    },

    {
        path: "/verifikasi-tuk/detail-verifikasi_tuk/:id",
        name: "detail-verifikasi_tuk",
        meta: { authRequired: true },
        component: () => import("./views/verifikasi-tuk/detail-verifikasi_tuk"),
    },

    {
        path: "/verifikasi-tuk/detail-verifikasi_tuk_penggunaan/:id",
        name: "detail-verifikasi_tuk_penggunaan",
        meta: { authRequired: true },
        component: () => import("./views/verifikasi-tuk/detail-verifikasi_tuk_penggunaan"),
    },
    {
        path: "/verifikasi-tuk/detail-verifikasi_tuk_tugas/:id",
        name: "detail-verifikasi_tuk_tugas",
        meta: { authRequired: true },
        component: () => import("./views/verifikasi-tuk/detail-verifikasi_tuk_tugas"),
    },

    {
        path: "/verifikasi-tuk/konfirmasi",
        name: "konfirmasi-kehadiran",
        meta: { authRequired: true },
        component: () => import("./views/verifikasi-tuk/konfirmasi-kehadiran"),
    },
    {
        path: "/verifikasi-tuk/surat-penetapan_tuk/:id",
        name: "preview-penetapan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/verifikasi-tuk/preview-penetapan_tuk"),
    },
    // (WIP) routes menu dynamic render
    {
        path: "/render",
        name: "render",
        meta: { authRequired: true },
        component: () => import("./views/render"),
    },
    // pendaftaran
    {
        path: "/pendaftaran",
        name: "all-pendaftaran",
        meta: { authRequired: true },
        component: () => import("./views/pendaftaran/all-pendaftaran"),
        prop: true,
    },
    {
        path: "/pendaftaran/add",
        name: "add-pendaftaran",
        meta: { authRequired: true },
        component: () => import("./views/pendaftaran/add-pendaftaran"),
    },
    {
        path: "/pendaftaran/edit/:id",
        name: "edit-pendaftaran",
        meta: { authRequired: true },
        component: () => import("./views/pendaftaran/edit-pendaftaran"),
    },
    {
        path: "/pendaftaran/detail-permohonan/:id",
        name: "detail-pendaftaran_permohonan",
        meta: { authRequired: true },
        component: () => import("./views/pendaftaran/detail-pendaftaran_permohonan"),
    },
    {
        path: "/pendaftaran-asesi",
        name: "all-pendaftaran_asesi",
        meta: { authRequired: true },
        component: () => import("./views/pendaftaran/asesi/all-pendaftaran_asesi"),
        prop: true,
    },

    {
        path: "/pendaftaran-asesi/detail-pendaftaran/:id",
        name: "detail-pendaftaran_asesi",
        meta: { authRequired: true },
        component: () => import("./views/pendaftaran/asesi/detail-pendaftaran_asesi"),
    },

    //PRA ASESMEN
    {
        path: "/pra-asesmen",
        name: "all-pra_asesmen",
        meta: { authRequired: true },
        component: () => import("./views/pra-asesmen/all-pra_asesmen"),
    },
    {
        path: "/pra-asesmen/add",
        name: "add-pra_asesmen",
        meta: { authRequired: true },
        component: () => import("./views/pra-asesmen/add-pra_asesmen"),
    },
    {
        path: "/pra-asesmen/konfirmasi",
        name: "konfirmasi-kehadiran_pra_asesmen",
        meta: { authRequired: true },
        component: () => import("./views/pra-asesmen/konfirmasi-kehadiran"),
    },
    {
        path: "/pra-asesmen/detail/:id",
        name: "detail-pra_asesmen",
        meta: { authRequired: true },
        component: () => import("./views/pra-asesmen/detail-pra_asesmen"),
    },
    {
        path: "/pra-asesmen/edit/:id",
        name: "edit-pra_asesmen",
        meta: { authRequired: true },
        component: () => import("./views/pra-asesmen/edit-pra_asesmen"),
    },
    {
        path: "/pra-asesmen/detail-skema/:id",
        name: "detail-skema_pra_asesmen",
        meta: { authRequired: true },
        component: () => import("./views/pra-asesmen/detail-skema_pra_asesmen"),
    },
    // asesmen asesi
    {
        path: "/add-asesmen/:id_verifikasi_tuk",
        name: "add-asesmen",
        meta: { authRequired: true },
        component: () => import("./views/asesmen/asesi/add-daftar-asesmen-asesi"),
    },
    {
        path: "/asesmen-asesi/",
        name: "all-daftar_asesmen_asesi",
        meta: { authRequired: true },
        component: () => import("./views/asesmen/asesi/all-daftar-asesmen-asesi"),
    },
    {
        path: "/detail-daftar-asesmen-asesi/:id",
        name: "detail-daftar-asesmen-asesi",
        meta: { authRequired: true },
        component: () => import("./views/asesmen/asesi/detail-daftar-asesmen-asesi"),
    },
    // asesmen asesor
    {
        path: "/asesmen-asesor/",
        name: "all-daftar_asesmen_asesor",
        meta: { authRequired: true },
        component: () => import("./views/asesmen/asesor/all-daftar-asesmen-asesor"),
    },
    {
        path: "/detail-asesmen-asesor/",
        name: "detail-asesmen-asesor",
        meta: { authRequired: true },
        component: () => import("./views/asesmen/asesor/detail-asesmen-asesor"),
    },
    //daftar-banding
    {
        path: "/pasca-asesmen/daftar-banding-admin/",
        name: "daftar-banding-admin",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-banding/admin-lsp/all-daftar-banding"),
    },
    {
        path: "/pasca-asesmen/add-surat-tugas-banding-admin/",
        name: "add-surat-tugas-banding-admin",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-banding/admin-lsp/add-surat-tugas-banding"),
    },
    {
        path: "/pasca-asesmen/add-asesor-banding/:id",
        name: "add-asesor-banding",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-banding/admin-lsp/add-asesor-banding"),
    },
    {
        path: "/pasca-asesmen/detail-banding-admin/:id",
        name: "detail-banding-admin",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-banding/admin-lsp/detail-daftar-banding"),
    },
    
    {
        path: "/pasca-asesmen/daftar-banding-asesor/",
        name: "daftar-banding-asesor",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-banding/asesor/all-daftar-banding-asesor"),
    },
    {
        path: "/pasca-asesmen/detail-banding-asesor/",
        name: "detail-banding-asesor",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-banding/asesor/detail-daftar-banding-asesor"),
    },
    //daftar-pleno
    {
        path: "/pasca-asesmen/daftar-pleno-admin/",
        name: "daftar-pleno-admin",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-pleno/admin-lsp/all-daftar-pleno"),
    },
    {
        path: "/pasca-asesmen/add-pleno-admin/",
        name: "add-surat-tugas-pleno-admin",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-pleno/admin-lsp/add-surat-tugas-pleno"),
    },
    {
        path: "/pasca-asesmen/add-asesor-pleno/:id",
        name: "add-asesor-pleno",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-pleno/admin-lsp/add-asesor-pleno"),
    },
    {
        path: "/pasca-asesmen/detail-daftar-pleno/:id",
        name: "detail-daftar-pleno",
        meta: { authRequired: true },
        component: () => import("./views/pasca-asesmen/daftar-pleno/admin-lsp/detail-daftar-pleno"),
    },

    // Menu Konfigurasi
    {
        path: "/konfig-muk",
        name: "konfig-muk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/muk/all-konfig_muk"),
    },
    {
        path: "/konfig-muk/add",
        name: "add-konfig_muk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/muk/add-konfig_muk"),
    },
    {
        path: "/konfig-muk/edit/:id",
        name: "edit-konfig_muk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/muk/edit-konfig_muk"),
    },
    {
        path: "/konfigurasi-dokumen-muk/",
        name: "konfigurasi-dokumen-muk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/dokumen-muk/list"),
    },
    {
        path: "/konfigurasi-dokumen-muk/edit/:id",
        name: "edit-konfigurasi-dokumen-muk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/dokumen-muk/edit"),
    },

    // KONFIGURASI SKEMA
    {
        path: "/konfigurasi/konfigurasi-skema",
        name: "all-konfigurasi_skema",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema/all-konfigurasi_skema"),
    },
    {
        path: "/konfigurasi/konfigurasi-skema/add",
        name: "add-konfigurasi_skema",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema/add-konfigurasi_skema"),
    },
    {
        path: "/konfigurasi/konfigurasi-skema/edit/:id",
        name: "edit-konfigurasi_skema",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema/edit-konfigurasi_skema"),
    },

    // KONFIGURASI SKEMA UNIT
    {
        path: "/konfigurasi/konfigurasi-skema-unit/:id_konfig",
        name: "all-konfigurasi_skema_unit",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-unit/all-konfigurasi_skema_unit"),
    },
    {
        path: "/konfigurasi/konfigurasi-skema-unit/add/:id_konfig",
        name: "add-konfigurasi_skema_unit",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-unit/add-konfigurasi_skema_unit"),
    },
    {
        path: "/konfigurasi/konfigurasi-skema-unit/edit/:id",
        name: "edit-konfigurasi_skema_unit",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-unit/edit-konfigurasi_skema_unit"),
    },

    // KONFIGURASI SKEMA ELEMEN
    {
        path: "/konfigurasi/konfigurasi-skema-elemen/:id_konfig_skema_unit",
        name: "all-konfigurasi_skema_elemen",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-elemen/all-konfigurasi_skema_elemen"),
    },
    {
        path: "/konfigurasi/konfigurasi-skema-elemen/add/:id_konfig_skema_unit",
        name: "add-konfigurasi_skema_elemen",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-elemen/add-konfigurasi_skema_elemen"),
    },
    {
        path: "/konfigurasi/konfigurasi-skema-elemen/edit/:id",
        name: "edit-konfigurasi_skema_elemen",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-elemen/edit-konfigurasi_skema_elemen"),
    },

    // KONFIGURASI SKEMA ELEMEN
    {
        path: "/konfigurasi/konfigurasi-skema-kuk/:id_konfig_skema_elemen",
        name: "all-konfigurasi_skema_kuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-kuk/all-konfigurasi_skema_kuk"),
    },
    {
        path: "/konfigurasi/konfigurasi-skema-kuk/add/:id_konfig_skema_elemen",
        name: "add-konfigurasi_skema_kuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-kuk/add-konfigurasi_skema_kuk"),
    },
    {
        path: "/konfigurasi/konfigurasi-skema-kuk/edit/:id",
        name: "edit-konfigurasi_skema_kuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-skema-kuk/edit-konfigurasi_skema_kuk"),
    },

    // KONFIGURASI RUANGAN TUK
    {
        path: "/konfigurasi/konfigurasi-ruangan-tuk",
        name: "all-konfigurasi_ruangan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-ruangan-tuk/all-konfigurasi_ruangan_tuk"),
    },
    {
        path: "/konfigurasi/konfigurasi-ruangan-tuk/add",
        name: "add-konfigurasi_ruangan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-ruangan-tuk/add-konfigurasi_ruangan_tuk"),
    },
    {
        path: "/konfigurasi/konfigurasi-ruangan-tuk/edit/:id",
        name: "edit-konfigurasi_ruangan_tuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-ruangan-tuk/edit-konfigurasi_ruangan_tuk"),
    },

    // KONFIGURASI ALAT TUK
    {
        path: "/konfigurasi/konfigurasi-alat-tuk",
        name: "all-konfigurasi_alat_tuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-alat-tuk/all-konfigurasi_alat_tuk"),
    },
    {
        path: "/konfigurasi/konfigurasi-alat-tuk/add",
        name: "add-konfigurasi_alat_tuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-alat-tuk/add-konfigurasi_alat_tuk"),
    },
    {
        path: "/konfigurasi/konfigurasi-alat-tuk/edit/:id",
        name: "edit-konfigurasi_alat_tuk",
        meta: { authRequired: true },
        component: () => import("./views/konfigurasi/konfigurasi-alat-tuk/edit-konfigurasi_alat_tuk"),
    },

    // routes Laporan
    {
        path: "/laporan/asesmen-selesai",
        name: "all-laporan_asesmen_selesai",
        meta: { authRequired: true },
        component: () => import("./views/laporan/all-laporan_asesmen"),
    },
    {
        path: "/laporan/asesi",
        name: "all-laporan_asesi",
        meta: { authRequired: true },
        component: () => import("./views/laporan/all-laporan_asesi"),
    },
   
    // Public View
    {
        path: "/esign/:user_id",
        name: "esign",
        component: () => import("./views/public/esign"),
    },
    {
        path: "/esign-penjamin-mutu/:id",
        name: "esign-penjamin-mutu",
        component: () => import("./views/public/esign-penjamin-mutu"),
    },
    {
        path: "/konfirmasi-ketersediaan-penyusunan",
        name: "konfirmasi-ketersediaan-penyusunan",
        component: () => import("./views/public/konfirmasi-ketersediaan-penyusunan"),
    },
    // log activity 
    {
        path: "/log-activity",
        name: "log-activity",
        component: () => import("./views/log-activity/all-log-activity"),
    },
];

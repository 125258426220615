<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import axios from "axios";
import Swal from "sweetalert2";

// import moment from "moment";

export default {
  page: {
    title: "Skema Sertifikasi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_skema_sertifikasi" },
      { width: "100px", label: "Kode Skema Sertifikasi", name: "kode_skema_sertifikasi" },
      { width: "100px", label: "Skema Sertifikasi", name: "nama_skema_sertifikasi" },
      { width: "100px", label: "Kategori", name: "skema_id" },
      { width: "100px", label: "Kategori Sub", name: "-" },
      { width: "100px", label: "Status SKKK", name: "-" },
      { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Skema Sertifikasi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Skema Sertifikasi",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      // variable Page Table
      // variable Page Table
      formPreviewDocMUK: null,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_skema_sertifikasi", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: 0,
        sort_order: "desc",
        filter_status: "ENABLE", // untuk filtering data
        kategori_id: null,
        kategori_sub_id: null,
        table_nama: "skema_sertifikasi",
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      // variabel referensi
      optionsKategori: [],
      optionsSubKategori: [],

      // variabel page
      skema_sertifikasi_kategori: "",
      skema_sertifikasi_kategori_sub: "",
      modalPreviewDokumenMUK: false,
    };
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  mounted() {
    let self = this;
    self.getDataTable();
    self.getSkemaSertifikasiKategori();
    self.getSkemaSertifikasiKategoriSub();
  },
  methods: {
    getSkemaSertifikasiKategori(){
      // load data
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/load-skema-sertifikasi-kategori",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsKategori = response.data.data.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getSkemaSertifikasiKategoriSub() {
      let self = this;
      var kategori_id = 1;
      if(self.skema_sertifikasi_kategori?.id_skema_sertifikasi_kategori){
        kategori_id = self.skema_sertifikasi_kategori?.id_skema_sertifikasi_kategori;
      }
      var config_load_skema_sertifikasi_kategori_sub= {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/load-skema-sertifikasi-kategori-sub/" + kategori_id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_load_skema_sertifikasi_kategori_sub)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsSubKategori = response.data.data.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/skema-sertifikasi"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-skema_sertifikasi",
          role_id: self.sessionRoleId
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list;
            self.configPagination(response_data_fix.pagination);
            self.currentTablePage = response_data_fix.pagination.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    changeStatusData(status, id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah Anda yakin untuk mengubah status?";
      } else {
        text_alert = "Apakah Anda yakin untuk mengubah status?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/skema-sertifikasi/" + id + '/status',
            data: {
              id: id,
              status: status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/skema-sertifikasi/" + id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    toPage: function (url) {
      let self = this;
      self.getDataTable(url);
    },
    getPreviewSKK(skema_id){
        let self = this;   
        self.formPreviewDocMUK = null;
        self.modalPreviewDokumenMUK = true;
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/show-skkk", {
                params: {
                  skema_id: skema_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then(function (response) {
                var res_fix = response.data;
                self.formPreviewDocMUK = res_fix.data.skk_url;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response;
                Swal.close();
            });
    },
    filterDatatable(){
      let self = this;
      self.tableData.kategori_id = self.skema_sertifikasi_kategori?.id_skema_sertifikasi_kategori;
      self.tableData.kategori_sub_id = self.skema_sertifikasi_kategori_sub?.id_skema_sertifikasi_kategori_sub;
      self.getDataTable();
    },
    exportExcel(){
      let self = this;
      let config = {
          method: "get",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/export/excel",
          params: self.tableData,
          headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
          },
          responseType: 'blob',
      };
      axios(config)
          .then(function (response) {
              var blob = new Blob([response.data], { type: 'application/vnd-ms-excel' })
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = 'Data Skema Sertifikasi.xls'
              link.click()
          })
          .catch(function (error) {
              console.log(error);
          });  
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Skema Sertifikasi</div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  &nbsp;
                  <router-link
                    :to="{ name: 'add-skema_sertifikasi' }"
                    class="btn btn-sm btn-primary m-1"
                    ><i class="fa fa-plus"></i> Tambah Data</router-link
                  >
                  <button class="btn btn-sm btn-success m-1" @click="exportExcel"><i class="fas fa-file-excel"></i> Export Data</button>
                  <!-- <button class="btn btn-sm btn-info m-1"><i class="fas fa-file-upload"></i> Impor Data</button> -->
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2">
                    <label for="statusTable">Status</label>
                    <select
                      class="form-select"
                      id="statusTable"
                      v-model="tableData.filter_status"
                    >
                      <option value="ENABLE">ENABLE</option>
                      <option value="DISABLE">DISABLE</option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="statusTable">Kategori</label>
                    <v-select
                      :options="optionsKategori"
                      label="nama_skema_sertifikasi_kategori"
                      v-model="skema_sertifikasi_kategori"
                      @update:modelValue="getSkemaSertifikasiKategoriSub"
                      placeholder="Pilih Kategori"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="statusTable">Kategori Sub</label>
                    <v-select
                      :options="optionsSubKategori"
                      label="nama_skema_sertifikasi_kategori_sub"
                      v-model="skema_sertifikasi_kategori_sub"
                      placeholder="Pilih Kategori"
                    ></v-select>
                  </div>
                  <div class="col-md-1">
                    <br />
                    <div class="btn btn-primary btn-sm mt-2" v-on:click="filterDatatable">
                      <i class="fa fa-filter"></i> Filter
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.per_page"
                            @change="getDataTable()"
                          >
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="searchDatatable"
                    />
                  </div>
                </div>
                <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="8">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                      <td class="text-center">
                        {{ ((parseInt(tableData.per_page) * parseInt(currentTablePage)) - parseInt(tableData.per_page)) + key_data + 1}}.
                      </td>
                      <td>
                        {{ row_data.kode_skema_sertifikasi }}
                      </td>
                      <td>
                        {{ row_data.nama_skema_sertifikasi }}
                      </td>
                      <td>
                        {{ row_data.sub_kategori.kategori.nama_skema_sertifikasi_kategori }}
                      </td>
                      <td>
                        {{ row_data.sub_kategori.nama_skema_sertifikasi_kategori_sub }}
                      </td>
                      <td class="text-center" @click="getPreviewSKK(row_data.id_skema_sertifikasi)">
                        <div v-if="row_data.status_skkk == true">
                          <div class="badge badge-pill badge-soft-success font-size-12">
                            SKKK BARU
                          </div>
                        </div>
                        <div v-else>
                          <div class="badge badge-pill badge-soft-danger font-size-12">
                            SKKK LAMA
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <div v-if="row_data.status == 'ENABLE'">
                          <div class="badge badge-pill badge-soft-success font-size-12">
                            ENABLE
                          </div>
                        </div>
                        <div v-else>
                          <div class="badge badge-pill badge-soft-danger font-size-12">
                            DISABLE
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <div v-if="row_data.status == 'ENABLE'">
                          <div class="btn-group">
                            <button type="button" class="btn btn-danger btn-sm" v-on:click="
                              changeStatusData('DISABLE', row_data.id_skema_sertifikasi)
                              ">
                              <i class="fa fa-times"></i> Disable
                            </button>
                            <router-link :to="{
                              name: 'edit-skema_sertifikasi',
                              params: { id: row_data.id_skema_sertifikasi },
                            }" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</router-link>
                          </div>
                        </div>
                        <div v-else>
                          <div class="btn-group">
                            <button type="button" class="btn btn-warning btn-sm" v-on:click="
                              changeStatusData('ENABLE', row_data.id_skema_sertifikasi)
                              ">
                              <i class="fas fa-undo"></i> Restore
                            </button>
                            <button type="button" class="btn btn-danger btn-sm"
                              v-on:click="deleteData(row_data.id_skema_sertifikasi)">
                              <i class="fa fa-trash"></i> Hapus
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </datatable>
              </div>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="modalPreviewDokumenMUK" title="Preview Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
      <div class="row">
          <form @submit.prevent="StorePreviewMUK">
              <iframe v-bind:src="formPreviewDocMUK" width="100%" height="400"></iframe>
              <div class="card-footer text-end">
                  <button type="button" class="btn btn-secodary mt-3" @click="modalPreviewDokumenMUK = false"><i class="fas fa-times"></i> Tutup</button>
              </div>
          </form>
      </div>
  </b-modal>
</template>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Edit Bukti Dokumen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Bukti Dokumen",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Bukti Dokumen",
          href: "/master/bukti-dokumen",
        },
        {
          text: "Edit Bukti Dokumen",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsDokumen: [],
      dokumen_selected: null,

      // variable Page
      id: this.$route.params.id,
      nama_bukti_dokumen: null,
      kode_bukti_dokumen: null,
      jenis_dokumen: [
        {
          value: "Bukti Relevan",
        },
        {
          value: "Bukti Persyaratan Dasar",
        },
      ],
      jenis_dokumen_selected: null
    };
  },
  mounted() {
    let self = this;
    var config_data_dokumen = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-dokumen-muk",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_data_dokumen)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsDokumen = response.data.data.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    // get data bukti-dokumen berdasarkan id
    var config = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/bukti-dokumen/" + self.id,
      params: {

      },
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          var data_edit = response_data_fix;
          self.kode_bukti_dokumen = data_edit.kode_bukti_dokumen;
          self.nama_bukti_dokumen = data_edit.nama_bukti_dokumen;
          self.dokumen_selected = data_edit.dokumen;
          self.jenis_dokumen_selected = data_edit.jenis_dokumen;
          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.close();
      });
  },
  methods: {
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/bukti-dokumen/"+self.id,
        data: {
          id: self.id,
          nama_bukti_dokumen: self.nama_bukti_dokumen,
          dokumen_id: self.dokumen_selected?.id,
          kode_bukti_dokumen: self.kode_bukti_dokumen,
          jenis_dokumen: self.jenis_dokumen_selected?.value,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master bukti dokumen segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-bukti_dokumen" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data.error;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    {{ errorArray }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kode</label>
                    <input type="text" class="form-control" v-model="kode_bukti_dokumen">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Bukti Dokumen</label>
                    <input type="text" class="form-control" v-model="nama_bukti_dokumen">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Jenis Dokumen</label>
                    <v-select
                        :options="jenis_dokumen"
                        label="value"
                        v-model="jenis_dokumen_selected"
                        placeholder="Pilih Jenis Dokumen"
                      ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kebutuhan</label>
                    <v-select
                        :options="optionsDokumen"
                        label="nama_dokumen_muk"
                        v-model="dokumen_selected"
                        placeholder="Pilih Kebutuhan"
                      ></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"
                      ><i class="fas fa-redo-alt"></i> Reset</b-button
                    >
                    <b-button type="submit" variant="primary" class="m-1"
                      ><i class="fa fa-save"></i> Simpan Bukti Dokumen</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Edit Provinsi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Provinsi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Provinsi",
          href: "/master/provinsi",
        },
        {
          text: "Edit Provinsi",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      id: this.$route.params.id,
      
      kode_provinsi: null,
      name_provinsi: null,
      
    };
  },
  mounted() {
    this.getProvinsi();
  },
  methods: {
    getProvinsi(){
     let self = this
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/provinsi/" + self.id,
        params: {},
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };  
      axios(config)
        .then(function (response) {
     
          let response_data = response.data.data
          self.kode_provinsi = response_data.kode_provinsi
          self.name_provinsi = response.data.data.name_provinsi
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/provinsi/"+self.id,
        data: {
          id_provinsi: self.id,
          kode_provinsi : self.kode_provinsi,
          name_provinsi : self.name_provinsi,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman provinsi segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-provinsi" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="kode_provinsi">Kode</label>
                    <input type="text" class="form-control" v-model="kode_provinsi">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="name_provinsi">Provinsi</label>
                    <input type="text" class="form-control" v-model="name_provinsi">
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"
                      ><i class="fas fa-redo-alt"></i> Reset</b-button
                    >
                    <b-button type="submit" variant="primary" class="m-1"
                      ><i class="fa fa-save"></i> Simpan Provinsi</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Tambah Kecamatan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Kecamatan",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Kecamatan",
          href: "/master/kecamatan",
        },
        {
          text: "Tambah Kecamatan",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsKabKota: [],
      optionsProvinsi: [],

      // variable Page
      menu_tipe: "",
      form : {
        kode_kecamatan: "",
        name_kecamatan: "",
        kab_kota_id: "",
        provinsi_id: "",
      },
    };
  },
  mounted() {
    this.getProvinsi();
  },
  methods: {
      getProvinsi() {
        let self = this;
        var config = {
          method: "get",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-provinsi",
          params: {
            search: self.search,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            access: "all-role",
            role_id: self.sessionRoleId
          },
      };
      axios(config)
        .then(function (response) {
          self.optionsProvinsi = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.message;
        });
      },
    getKabkota() {
        let self = this;
        var provinsi_id = 1;
        if(self.form.provinsi_id?.id){
          provinsi_id = self.form.provinsi_id?.id;
        }
        self.form.kab_kota_id = null;
        var config = {
          method: "get",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-kabkota/" + provinsi_id,
          params: {
            search: self.search,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            access: "all-role",
            role_id: self.sessionRoleId
          },
      };
      axios(config)
        .then(function (response) {
          self.optionsKabKota = response.data.data.referensi;
          console.log(self.optionsKabKota);
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.message;
        });
    },
    StoreData() {
        let self = this;
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
        });

        var config_store_data = {
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/kecamatan",
          data: {
            kode_kecamatan: self.form.kode_kecamatan,
            name_kecamatan: self.form.name_kecamatan,
            kabkota_id: self.form.kab_kota_id?.id,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        };

        axios(config_store_data)
          .then(function (response) {
            console.log(response);
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master Kecamatan kota segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                self.$router.push({ name: "all-kecamatan" });
              }
            });
          })
          .catch(function (error) {
            self.axiosCatchError = error.response.data.data;
            Swal.close();
          });
      },
  }
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Provinsi</label>
                    <v-select
                      :options="optionsProvinsi"
                      label="name_provinsi"
                      v-model="form.provinsi_id"
                      placeholder="Pilih Provinsi"
                      @update:modelValue="getKabkota"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kabupaten Kota</label>
                    <v-select
                      :options="optionsKabKota"
                      label="name_kabkota"
                      v-model="form.kab_kota_id"
                      placeholder="Pilih Kabupaten Kota"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kode</label>
                    <input type="text" class="form-control" v-model="form.kode_kecamatan" placeholder="Masukan Kode Kecamatan">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kecamatan</label>
                    <input type="text" class="form-control" v-model="form.name_kecamatan" placeholder="Masukan Nama Kecamatan">
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"
                      ><i class="fas fa-redo-alt"></i> Reset</b-button
                    >
                    <b-button type="submit" variant="primary" class="m-1"
                      ><i class="fa fa-save"></i> Simpan Kecamatan</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

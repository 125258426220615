<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Tambah TUK",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah TUK",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "TUK",
          href: "/master/tuk",
        },
        {
          text: "Tambah TUK",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsTUKJenis: [],

      // variable Page
      tuk_jenis_selected: [],
    };
  },
  mounted() {
    let self = this;

    // load data tuk jenis
    var config_data_menu_parent = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-tuk-jenis",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_data_menu_parent)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsTUKJenis = response.data.data.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/tuk",
        data: {
          tuk_nomor: self.tuk_nomor,
          tuk_nama: self.tuk_nama,
          jenis_tuk_id: JSON.stringify(self.tuk_jenis_selected),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master tuk segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-tuk" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data.error;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    {{ errorArray }}
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- <div class="form-group col-md-12">
                  <label for="tipe_menu">Kode</label>
                  <input type="text" class="form-control" v-model="tuk_nomor">
                </div> -->
                <div class="form-group col-md-12">
                  <label for="tipe_menu">Nama TUK</label>
                  <input type="text" class="form-control" v-model="tuk_nama">
                </div>
                <div class="form-group col-md-12">
                  <label for="tipe_menu">Jenis TUK</label>
                  <v-select
                    :options="optionsTUKJenis"
                    label="jenis_nama"
                    v-model="tuk_jenis_selected"
                    placeholder="Pilih Jenis TUK"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div class="row mt-3">
                <hr>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"
                      ><i class="fas fa-redo-alt"></i> Reset</b-button
                    >
                    <b-button type="submit" variant="primary" class="m-1"
                      ><i class="fa fa-save"></i> Simpan TUK</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  page: {
    title: "Tambah User",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah User",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "User",
          href: "/master/user",
        },
        {
          text: "Tambah User",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variable page
      user_username: null,
      user_password: null,
      user_password_confirm: null,
      user_name: null,
      user_photo: null,
      list_role: [],
      selected_role: [],
    };
  },
  mounted() {
    // get data role
    let self = this;
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    var config_list_role = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/role-active",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_list_role)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.list_role = response.data.data.referensi;
          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    // Method dari masing masing fungsi di dalam file ini
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users",
        data: {
          username: self.user_username,
          password: self.user_password,
          password_confirm: self.user_password_confirm,
          name: self.user_name,
          path_photo: self.user_photo,
          nik: self.user_nik,
          no_ktp: self.user_ktp,
          jabatan: self.user_jabatan,
          divisi: self.user_divisi,
          alamat: self.user_alamat,
          email: self.user_email,
          selected_role: JSON.stringify(self.selected_role),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master user segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-user" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    inputFilePhoto() {
      let self = this;
      if ($("#formPhoto")[0].files[0]) {
        if ($("#formPhoto")[0].files[0].size < 2242880) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formPhoto")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.user_photo = path_file;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="NIK"
                    label-for="formrow-username-input"
                  >
                    <b-form-input
                      id="formrow-username-input"
                      placeholder="Masukkan NIK..."
                      type="text"
                      v-model="user_nik"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="No. KTP"
                    label-for="formrow-username-input"
                  >
                    <b-form-input
                      id="formrow-username-input"
                      placeholder="Masukkan No KTP..."
                      type="text"
                      v-model="user_ktp"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Nama <span class="text-danger"> * wajib diisi</span></label>
                    <input type="text" id="" class="form-control" placeholder="Masukkan Nama..." v-model="user_name">
                  </div>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Jabatan"
                    label-for="formrow-username-input"
                  >
                    <b-form-input
                      id="formrow-username-input"
                      placeholder="Masukkan Jabatan..."
                      type="text"
                      v-model="user_jabatan"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Divisi"
                    label-for="formrow-username-input"
                  >
                    <b-form-input
                      id="formrow-username-input"
                      placeholder="Masukkan Divisi..."
                      type="text"
                      v-model="user_divisi"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Alamat"
                    label-for="formrow-username-input"
                  >
                    <b-form-input
                      id="formrow-username-input"
                      placeholder="Masukkan Alamat..."
                      type="text"
                      v-model="user_alamat"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Email"
                    label-for="formrow-username-input"
                  >
                    <b-form-input
                      id="formrow-username-input"
                      placeholder="Masukkan Email..."
                      type="text"
                      v-model="user_email"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Username <span class="text-danger"> * wajib diisi</span></label>
                      <input type="text" id="" class="form-control" placeholder="Masukkan Username..." v-model="user_username">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Password <span class="text-danger"> * wajib diisi</span></label>
                    <input type="text" id="" class="form-control" placeholder="Masukkan Password..." v-model="user_password">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Konfirmasi Password <span class="text-danger"> * wajib diisi</span></label>
                    <input type="text" id="" class="form-control" placeholder="Masukkan Konfirmasi Password..." v-model="user_password_confirm">
                  </div>
                  <hr />
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="formPhoto" class="form-label">Pilih Foto</label>
                    <input
                      class="form-control"
                      type="file"
                      id="formPhoto"
                      v-on:change="inputFilePhoto()"
                    />
                    <div
                      class="respond-input-file float-left"
                      id="uploadLoading"
                    ></div>
                    <small class="float-right" style="color: red"
                      >*) Max File 2 MB</small
                    >
                  </div>
                  <hr />
                </div>
                <div class="col-md-12">
                  <div class="mt-0">
                    <h5 class="font-size-14">
                      <i class="mdi mdi-arrow-right text-primary me-1"></i>
                      Pilih Role <span class="text-danger"> * wajib diisi</span>
                    </h5>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div
                        v-for="(row_data, key_data) in list_role"
                        :key="key_data"
                      >
                        <div class="form-check-primary mb-3">
                          <b-form-checkbox
                            :value="row_data.id_role"
                            :unchecked-value="row_data.id_role"
                            v-model="selected_role"
                          >
                            {{ row_data.role_name }}
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-start">
                    <router-link
                      :to="{ name: 'all-user' }"
                      class="btn btn-warning"
                      ><i class="fa fa-arrow-left"></i> Kembali</router-link
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <b-button type="reset" variant="danger"
                      ><i class="fa fa-redo-alt"></i> Reset</b-button
                    >
                    &nbsp;
                    <b-button type="submit" variant="primary"
                      ><i class="fa fa-save"></i> Simpan</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Tambah Kabupaten Kota",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Kabupaten Kota",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Kabupaten Kota",
          href: "/master/provinsi",
        },
        {
          text: "Tambah Kabupaten Kota",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsProvinsi: [],

      // variable Page
      menu_tipe: "",

      form_menu: false,
      nama_menu: null,
      icon_menu: null,
      link_path_menu: null,
      link_name_menu: null,
      urutan_menu: null,
      parent_menu: [],

      form_label: false,
      nama_label: null,
      urutan_label: null,
      search: null,
      form : {
        provinsi_id: null,
        kode_kabkota: null,
        name_kabkota: null,
      },
    };
  },
  mounted() {
    this.getProvinsi();
  },
  methods: {
      getProvinsi() {
        let self = this;
        var config = {
          method: "get",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-provinsi",
          params: {
            search: self.search,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            access: "all-role",
            role_id: self.sessionRoleId
          },
        };
        axios(config)
          .then(function (response) {
            self.optionsProvinsi = response.data.data.referensi;
          })
          .catch(function (error) {
            self.axiosCatchError = error.response.data.message;
          });
      },
      StoreData() {
        let self = this;
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
        });

        var config_store_data = {
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/kabkota",
          data: {
            kode_kabkota : self.form.kode_kabkota,
            name_kabkota : self.form.name_kabkota,
            provinsi_id : self.form.provinsi_id?.id,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        };

        axios(config_store_data)
          .then(function (response) {
            console.log(response);
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master Kabubaten kota segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                self.$router.push({ name: "all-kab_kota" });
              }
            });
          })
          .catch(function (error) {
            self.axiosCatchError = error.response.data.data;
            Swal.close();
          });
      },
    },
    
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kode</label>
                    <input type="text" class="form-control" v-model="form.kode_kabkota">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Kabupaten Kota</label>
                    <input type="text" class="form-control" v-model="form.name_kabkota">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Provinsi</label>
                    <v-select
                      :options="optionsProvinsi"
                      label="name_provinsi"
                      v-model="form.provinsi_id"
                      placeholder="Pilih Provinsi"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"
                      ><i class="fas fa-redo-alt"></i> Reset</b-button
                    >
                    <b-button type="submit" variant="primary" class="m-1"
                      ><i class="fa fa-save"></i> Simpan Kabupaten Kota</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
